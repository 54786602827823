exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-authors-strapi-author-name-js": () => import("./../../../src/pages/authors/{StrapiAuthor.name}.js" /* webpackChunkName: "component---src-pages-authors-strapi-author-name-js" */),
  "component---src-pages-categories-strapi-product-sub-category-slug-js": () => import("./../../../src/pages/categories/{StrapiProductSubCategory.slug}.js" /* webpackChunkName: "component---src-pages-categories-strapi-product-sub-category-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-strapi-product-listing-slug-js": () => import("./../../../src/pages/landing/{StrapiProductListing.slug}.js" /* webpackChunkName: "component---src-pages-landing-strapi-product-listing-slug-js" */),
  "component---src-pages-legal-technology-centers-strapi-product-category-name-js": () => import("./../../../src/pages/legal-technology-centers/{StrapiProductCategory.name}.js" /* webpackChunkName: "component---src-pages-legal-technology-centers-strapi-product-category-name-js" */),
  "component---src-pages-library-strapi-article-title-js": () => import("./../../../src/pages/library/{StrapiArticle.title}.js" /* webpackChunkName: "component---src-pages-library-strapi-article-title-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-strapi-product-listing-slug-js": () => import("./../../../src/pages/products/{StrapiProductListing.slug}.js" /* webpackChunkName: "component---src-pages-products-strapi-product-listing-slug-js" */),
  "component---src-pages-products-strapi-product-listing-slug-reviews-js": () => import("./../../../src/pages/products/{StrapiProductListing.slug}/reviews.js" /* webpackChunkName: "component---src-pages-products-strapi-product-listing-slug-reviews-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-resources-strapi-category-name-js": () => import("./../../../src/pages/resources/{StrapiCategory.name}.js" /* webpackChunkName: "component---src-pages-resources-strapi-category-name-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-sell-on-lawnext-js": () => import("./../../../src/pages/sell-on-lawnext.js" /* webpackChunkName: "component---src-pages-sell-on-lawnext-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-vendor-directory-program-terms-js": () => import("./../../../src/pages/vendor-directory-program-terms.js" /* webpackChunkName: "component---src-pages-vendor-directory-program-terms-js" */),
  "component---src-pages-write-a-review-js": () => import("./../../../src/pages/write-a-review.js" /* webpackChunkName: "component---src-pages-write-a-review-js" */),
  "component---src-templates-product-pricing-js": () => import("./../../../src/templates/productPricing.js" /* webpackChunkName: "component---src-templates-product-pricing-js" */),
  "component---src-templates-sub-product-buy-res-js": () => import("./../../../src/templates/subProductBuyRes.js" /* webpackChunkName: "component---src-templates-sub-product-buy-res-js" */),
  "component---src-templates-sub-product-buyers-guide-js": () => import("./../../../src/templates/subProductBuyersGuide.js" /* webpackChunkName: "component---src-templates-sub-product-buyers-guide-js" */),
  "component---src-templates-sub-product-news-js": () => import("./../../../src/templates/subProductNews.js" /* webpackChunkName: "component---src-templates-sub-product-news-js" */)
}

